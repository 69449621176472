import React, {useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom'
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../services/firebase";
import {useDispatch, useSelector} from "react-redux";
import Helpers from '../utils/helpers'
import CustomLoaderSpinner from '../components/general/CustomLoaderSpinner'
import CustomNavBar from '../components/general/CustomNavBar'
import Styles from './Home.module.scss'
import {fetchAllVehicles} from '../redux/slices/vehiclesSlice'
import {fetchOldReservations, fetchUpcomingReservations} from "../redux/slices/reservationsSlice";
import {LOGGER} from '../utils/Logger'
import {addToken, changeUserAccess} from '../redux/slices/authSlice'
import {getFirebaseSettings, getSettingsData} from '../redux/slices/settingsSlice'
import Colors from '../config/colors'
import {addUser, getAllUsers} from '../redux/slices/userSlice'
import Config from "../config";
import axios from "axios";
import CustomModal from "../components/general/CustomModal";
import CONSTANTS from '../config/constants'
import {getAllClients} from "../redux/slices/clientsSlice";
import {fetchAllPartnerSplits_nameAndId} from '../redux/slices/partnerSplitSlice';
import {getAllNotifications} from "../redux/slices/notificationsSlice";
import {getAllLeads} from '../redux/slices/leadsSlice'


function Home() {
	const navigate = useNavigate();
	const dispatch = useDispatch()

	const firebaseSettings = useSelector(state => getFirebaseSettings(state.settings))
	const checkAccess = useSelector(state => state.auth.access_allowed)
	const user = useSelector(state => state.user)
	const token = useSelector(state => state.auth.token)

	const [loading, setLoading] = useState(false)
	const [showAccessModal, setShowAccessModal] = useState(false)

	// useEffect(() => {
	//   //get firebae token and save to backend
	//   if (token) {
	//     getFCMToken()
	//   }
	//
	// }, [token])

	// const getFCMToken = async () => {
	//   try {
	//     console.log('getting FCM token')
	//     let fcmToken = await getToken(firebaseMessaging, {vapidKey: Config.FCM_KEY})
	//     if (fcmToken) {
	//       console.log('got the FCM token')
	//       let tempSettings = JSON.parse(JSON.stringify(firebaseSettings))
	//       tempSettings['_id'] = 'firebaseSettings'
	//       tempSettings['token'] = fcmToken
	//       dispatch(updateSettingsData({token, data: tempSettings}))
	//     } else {
	//       let permission = await Notification.requestPermission()
	//       if (permission === 'granted') {
	//         console.log('permission granted')
	//         getFCMToken()
	//       } else {
	//         LOGGER.warn('Permission not provided for notifications')
	//       }
	//     }
	//   } catch (err) {
	//     LOGGER.error('An error occurred while retrieving token. ', err.message);
	//     let permission = await Notification.requestPermission()
	//     if (permission === 'granted') {
	//       console.log('permission granted')
	//       getFCMToken()
	//     } else {
	//       LOGGER.warn('Permission not provided for notifications')
	//     }
	//   }
	//
	// }

	useEffect(() => {
		if (!checkAccess) {
			setShowAccessModal(true)
		}
	}, [checkAccess])

	const getIdToken = (authUser) => {
		authUser.getIdToken(true)
		.then(function (idToken) {
			// Send token to your backend via HTTPS
			let uid = authUser.uid
			console.log('id toen is', idToken)
			dispatch(addToken(idToken))
			dispatch(getAllUsers({token: idToken}))
			//get user data to see if they have access
			if (Config.WHITELIST_UIDS.includes(uid)) {
				Helpers.updateUserStore(authUser)
				Helpers.updateMongoUserStore({uid: authUser.uid, email: authUser.email, role: 1})
			} else {
				getUserData(uid, idToken)
				.then(res => {
					if (!res.data) {
						window.alert('Cannot find profile information')
						auth.signOut()
					} else {
						if (!res.data.enabled) {
							window.alert('Your account access has been revoked')
							auth.signOut()
						} else {
							Helpers.updateUserStore(authUser)
							Helpers.updateMongoUserStore(res.data)
						}
					}
				}).catch(err => {
					LOGGER.error('error when getting user data from DB', err)
					window.alert('There was an error getting user information')
				})
			}
		}).catch(function (error) {
			console.log('error when getting id token from uid', error)
		});
	}

	useEffect(() => {
		let listener = onAuthStateChanged(auth, (authUser) => {
			console.log('auth state cahnged')
			if (authUser) {
				getIdToken(authUser)
				//refresh the token every 30 minutes
				setInterval(() => {
					getIdToken(authUser)
				}, [30 * 60 * 1000])
			} else {
				dispatch(addUser(null))
				navigate('/login')
			}
		});
		return listener
	}, [])

	useEffect(() => {
		if (token) {
			const interval = setInterval(() => {
				dispatch(getAllNotifications({token}))
				dispatch(getAllLeads({token}))
			}, 120000)

			return () => clearInterval(interval)
		}
	}, [token])

	const getUserData = async (uid, idToken) => {
		const config = {
			method: 'get',
			url: `${Config.BACKEND_URL}users?uid=${uid}`,
			headers: {Authorization: idToken, contentType: "application/json",}
		}

		return axios(config)
	}

	useEffect(() => {
		if (user?.user && token) {
			dispatch(fetchAllVehicles({token}))
			dispatch(fetchUpcomingReservations({token, startDate: new Date().toISOString()}))
			dispatch(fetchOldReservations({token}))
			dispatch(getSettingsData({token}))
			dispatch(getAllClients({token}))
			dispatch(fetchAllPartnerSplits_nameAndId({token}))
			dispatch(getAllNotifications({token}))
			dispatch(getAllLeads({token}))
		}
	}, [user, token])

	return (
		<main>
			<CustomModal
				show={showAccessModal}
				handleClose={() => {
					setShowAccessModal(false)
					dispatch(changeUserAccess(true))
				}}
				containerWidth={window.innerWidth / 2}
				containerHeight={200}
			>
				<div style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					backgroundColor: Colors.theme,
					height: '100%',
					padding: '10px 0px'
				}}>
					<label style={{width: '100%', textAlign: 'center', fontSize: CONSTANTS.headerSize, fontWeight: 'bold'}}>Access
						Denied</label>
					<label style={{width: '90%', fontSize: CONSTANTS.entryTextSize, marginTop: 30, textAlign: 'center'}}>Your
						account does not have enough permission to access this feature.</label>
					<label style={{width: '90%', fontSize: CONSTANTS.entryTextSize, marginTop: 10, textAlign: 'center'}}>Please
						contact support if you think this is a mistake.</label>
				</div>
			</CustomModal>

			{
				loading ?
					<div className={Styles.container} style={{
						height: '100vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: Colors.themeLight
					}}>
						<CustomLoaderSpinner/>
					</div>
					:
					<div className={Styles.container}>
						<CustomNavBar accessCallback={() => setShowAccessModal(true)}/>
						<Outlet/>
					</div>
			}
		</main>
	);
}

export default Home;
