import React, {useEffect, useState} from 'react';
import {auth} from '../../services/firebase'
import {signInWithEmailAndPassword} from "firebase/auth";
import Styles from './styles/UserLogin.module.scss'
import CustomTextField from '../../components/general/CustomTextField'
import CustomButtonContained from '../../components/general/CustomButtonContained'
import Helpers from '../../utils/helpers'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom'
import Colors from '../../config/colors'
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import Config from '../../config'
import ChauffeurPdfViewerComponent from '../../components/general/ChauffeurPdfViewerComponent'


export default function Room() {
	let dispatch = useDispatch()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [showError, setShowError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [showPdf, setShowPdf] = useState(false)
	let navigate = useNavigate()

	const user = useSelector(state => {
		// console.log('inside home selector', state.user)
		return state.user
	})

	useEffect(() => {
		if (user.user) {
			if (user.user.mongoUser?.role === 4)
				navigate('/home/availability')
			else
				navigate('/home/overview')
		}
	}, [user])

	const onSubmitClicked = () => {
		if (!password || password.length === 0 || !email || email.length === 0) {
			setShowError(true)
			setErrorMessage('Please enter a valid email and password')
			return
		}

		if (!Helpers.validateEmail(email)) {
			setShowError(true)
			setErrorMessage('Please enter a valid email')
			return
		}

		signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			// Signed in
			const user = userCredential.user;
			Helpers.updateUserStore(user)
		})
		.catch((error) => {
			let errorCode = error.code
			setShowError(true)
			console.log('error', error)
			if (errorCode === 'auth/user-not-found') {
				setErrorMessage('Account not found')
			} else if (errorCode === 'auth/wrong-password') {
				setErrorMessage('Wrong password')
			} else {
				setErrorMessage('Unknown error')
			}
		});

	}

	if (showPdf) {
		return (
			<div style={{height: '100vh', width: '100%'}}>
				<ChauffeurPdfViewerComponent
					document={"/demo.pdf"}
				/>
			</div>
		)
	}


	return (
		<main>
			<div className={Styles.container} style={{backgroundColor: Colors.backgroundColor}}>
				<PositionedSnackbar
					onClose={() => {
						setShowError(false)
						setErrorMessage('')
					}}
					severity={'error'}
					openFlag={showError}
					message={errorMessage}
				/>

				<p style={{fontWeight: 'bold', fontSize: 50, marginTop: 80}}>{Config.NAME}</p>
				{/*<img*/}
				{/*	style={{marginTop: 50}}*/}
				{/*	className={Styles.logo}*/}
				{/*	src={DDELogo} height={200} width={300} />*/}

				<div className={Styles.formContainer}>
					<CustomTextField
						value={email}
						onChange={(text) => setEmail(text)}
						label={'Email'}/>
					<CustomTextField
						type={'password'}
						value={password}
						onChange={(text) => setPassword(text)}
						label={'Password'}/>

					<CustomButtonContained
						text={'Submit'}
						onClick={onSubmitClicked}
					/>

				</div>

			</div>

		</main>
	);
}
