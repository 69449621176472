import {addMongoUser, addUser} from "../redux/slices/userSlice";
import store from '../redux/store'
import Config from '../config'
import moment from 'moment'
import {PAYMENT_STATUSES} from "../metadata/enums";


const functions = {
	updateUserStore: function (firebaseUser) {
		if (firebaseUser) {
			store.dispatch(addUser({
				email: firebaseUser.email,
				uid: firebaseUser.uid
			}))
		}
	},

	getFormattedExpiry: function (text, expiry) {
		if (text.length === 2 && expiry?.length === 1) {
			text += '/'
		} else if (text.length === 2 && expiry.length === 3) {
			text = text.substring(0, text.length - 1)
		}

		return text
	},

	updateMongoUserStore: function (user) {
		if (user) {
			store.dispatch(addMongoUser(user))
		}
	},

	numFormatter: function (num) {
		if (isNaN(num)) return null
		return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
	},

	validateEmail: function (email) {
		return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	},

	getKeyByValue: (object, value) => {
		return Object.keys(object).find(key => object[key] === value);
	},

	formatPhoneNumber: (phoneNumberString) => {
		let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
		let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
		if (match) {
			return '(' + match[1] + ') ' + match[2] + '-' + match[3]
		}
		return null

	},

	getTotalDays: (reservation) => {
		let {pickup_datetime, dropoff_datetime} = reservation
		const finalPickupDatetime = moment(pickup_datetime);
		const finalReturnDatetime = moment(dropoff_datetime);
		const totalHours = finalReturnDatetime.diff(finalPickupDatetime, 'hours');
		if (totalHours <= 24) return 1;
		const totalDays = Math.floor(totalHours / 24);
		const reminder = totalHours % 24;
		if (reminder > 2) return totalDays + 1;
		return totalDays;
	},

	checkPhoneNumberFormat: (value) => {
		// if input value is falsy eg if the user deletes the input, then just return
		if (!value) return value;

		// clean the input for any non-digit values.
		const phoneNumber = value.replace(/[^\d]/g, '');

		// phoneNumberLength is used to know when to apply our formatting for the phone number
		const phoneNumberLength = phoneNumber.length;

		// we need to return the value with no formatting if its less then four digits
		// this is to avoid weird behavior that occurs if you  format the area code to early

		if (phoneNumberLength < 4) return phoneNumber;

		// if phoneNumberLength is greater than 4 and less the 7 we start to return
		// the formatted number
		if (phoneNumberLength < 7) {
			return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
		}

		// finally, if the phoneNumberLength is greater then seven, we add the last
		// bit of formatting and return it.
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
			3,
			6
		)}-${phoneNumber.slice(6, 10)}`;
	},

	checkAccess: function (mongoUser, rolesMap, permissionKey) {
		if (Config.WHITELIST_UIDS[mongoUser?.uid]) {
			return true
		} else {
			return rolesMap[mongoUser.role]['permissions'][permissionKey]
		}
	},

	getRolesMap: (state) => {
		let map = {}
		state.settings.forEach(setting => {
			if (setting._id === 'permissions') {
				setting.roles.forEach(role => map[role.id] = role)
			}
		})
		return map
	},

	isBrokerAccount: (mongoUser) => {
		return mongoUser?.role === 4
	},

	getRentalsPricingObject: function (reservation, vehicle, priceSettings) {
		return {
			deposit: reservation?.pricing ? reservation?.pricing?.deposit : vehicle?.deposit || 0,
			mileage_overage_price: reservation?.pricing ? reservation?.pricing.mileage_overage_price : vehicle?.mileage_overage_price || 0,
			service_tax: reservation?.pricing ? reservation?.pricing?.service_tax : priceSettings?.service_tax['rentals'] || 0,
			sales_tax: reservation?.pricing ? reservation?.pricing?.sales_tax : priceSettings?.sales_tax || 0,
			mileage_allowance: reservation?.pricing?.mileage_allowance ? reservation?.pricing?.mileage_allowance : reservation?.check_in?.mileage_allowance || vehicle?.mileage_allowance || 0,
			location_fee: reservation?.pricing ? reservation?.pricing?.location_fee || 0 : priceSettings?.location_fee || 0,
			card_fee: reservation?.pricing ? reservation?.pricing?.card_fee || 0 : priceSettings?.card_fee || 0,
			fuel_charge: reservation?.pricing ? reservation?.pricing?.fuel_charge || 0 : vehicle?.fuel_charge || 0,
			extras: reservation?.pricing?.extras || [], //array of objects {name: '', price: ''}
			discount: reservation?.pricing?.discount || {},
			mileage_package: reservation?.pricing?.mileage_package || {} //this will contain {value: 100 miles, price: $200}
		}
	},

	getChauffeurPricingObject: function (reservation, vehicle, priceSettings) {
		return {
			deposit: reservation?.pricing ? reservation?.pricing?.deposit : vehicle?.deposit || 0,
			mileage_overage_price: reservation?.pricing ? reservation?.pricing?.mileage_overage_price : vehicle?.mileage_overage_price || 0,
			service_tax: reservation?.pricing ? reservation?.pricing?.service_tax : priceSettings?.service_tax['chauffeur'] || 0,
			mileage_allowance: reservation?.pricing?.mileage_allowance ? reservation?.pricing?.mileage_allowance : reservation?.check_in?.mileage_allowance || vehicle?.mileage_allowance || 0,
			card_fee: reservation?.pricing ? reservation?.pricing?.card_fee || 0 : priceSettings?.card_fee || 0,
			extras: reservation?.pricing?.extras || [], //array of objects {name: '', price: ''}
			discount: reservation?.pricing?.discount || {},
			mileage_package: reservation?.pricing?.mileage_package || {} //this will contain {value: 100 miles, price: $200}
		}
	},
	getRentalTotalPricing: function (reservation) {
		const getTotalDays = () => {
			const finalPickupDatetime = moment(pickup_datetime);
			const finalReturnDatetime = moment(dropoff_datetime);
			const totalHours = finalReturnDatetime.diff(finalPickupDatetime, 'hours');
			if (totalHours <= 24) return 1;
			const totalDays = Math.floor(totalHours / 24);
			const reminder = totalHours % 24;
			if (reminder > 2) return totalDays + 1;
			return totalDays;
		};

		const getDiscountedAmount = (subTotal) => {
			if (pricing?.discount?.label) {
				if (pricing.discount.label.toLowerCase() === 'custom amount') return pricing.discount.value;
				return (pricing.discount.value * subTotal) / 100;
			}
			return 0;
		};

		const {
			pricing, pickup_datetime, dropoff_datetime, check_in, check_out, pickup_location, dropoff_location, totalPrice,
		} = reservation;
		const addOns = {};
		let totalMileageAllowance = pricing?.mileage_allowance * getTotalDays();
		if (pricing?.mileage_package?.value) {
			totalMileageAllowance += Number(pricing?.mileage_package?.value);
		}

		if (check_in && check_out) {
			// if te check out info has been added, calculate the add ons for mileage and fuel
			const startFuel = check_in.fuel;
			const endFuel = check_out.fuel;
			if (endFuel < startFuel) {
				addOns.fuel = {
					value: startFuel - endFuel,
					price: Math.round(
						((startFuel - endFuel) / 10) * pricing?.fuel_charge,
					),
				};
			}

			const startMileage = check_in.mileage;
			const endMileage = check_out.mileage;
			if (endMileage - startMileage > totalMileageAllowance) {
				addOns.mileage = {
					value: endMileage - startMileage - totalMileageAllowance,
					price: Number(
						(endMileage - startMileage - totalMileageAllowance)
						* pricing?.mileage_overage_price,
					),
				};
			}
		}

		if (typeof pickup_location !== 'string') addOns.location1 = {value: '', price: pricing?.location_fee};

		if (typeof dropoff_location !== 'string') addOns.location2 = {value: '', price: pricing?.location_fee};

		let tempTotalPrice = totalPrice;
		if (Object.keys(addOns).length > 0) {
			Object.values(addOns).forEach(
				(value) => {
					tempTotalPrice += Number(value.price);
				},
			);
		}

		if (pricing?.mileage_package?.value) {
			// mileag package cost must be added as well
			tempTotalPrice += Number(pricing?.mileage_package.price);
		}

		if (pricing?.extras && pricing.extras.length > 0) {
			pricing.extras.forEach((extra, index) => {
				tempTotalPrice += Number(extra.price);
			});
		}

		const discount = Number(getDiscountedAmount(tempTotalPrice));
		const subTotal = tempTotalPrice - discount;

		const cardFee = pricing?.card_fee ? (subTotal * pricing?.card_fee) / 100 : 0;
		const salesFee = pricing?.sales_tax ? (subTotal * pricing?.sales_tax) / 100 : 0;
		const serviceFee = pricing?.service_tax ? (subTotal * pricing?.service_tax) / 100 : 0;
		const total = subTotal + serviceFee + salesFee + cardFee;

		return total;
	},

	getChauffeurTotalPricing: function (reservation) {
		const getTotalDays = () => {
			const finalPickupDatetime = moment(pickup_datetime);
			const finalReturnDatetime = moment(dropoff_datetime);
			const totalHours = finalReturnDatetime.diff(finalPickupDatetime, 'hours');
			if (totalHours <= 24) return 1;
			const totalDays = Math.floor(totalHours / 24);
			const reminder = totalHours % 24;
			if (reminder > 2) return totalDays + 1;
			return totalDays;
		};

		const getDiscountedAmount = (subTotal) => {
			if (pricing?.discount?.label) {
				if (pricing.discount.label.toLowerCase() === 'custom amount') return pricing.discount.value;
				return (pricing.discount.value * subTotal) / 100;
			}
			return 0;
		};

		const {
			pricing, pickup_datetime, dropoff_datetime, check_in, check_out, pickup_location, dropoff_location, totalPrice,
		} = reservation;

		const addOns = {};
		let totalMileageAllowance = pricing?.mileage_allowance * getTotalDays();
		let showMileagePackage = false;
		if (pricing?.mileage_package?.value) {
			totalMileageAllowance += Number(pricing?.mileage_package?.value);
			showMileagePackage = true;
		}

		if (reservation.check_in && reservation.check_out) {
			// if te check out info has been added, calculate the add ons for mileage

			const startMileage = reservation.check_in.mileage;
			const endMileage = reservation.check_out.mileage;
			if ((endMileage - startMileage) > totalMileageAllowance) {
				addOns.mileage = {
					value: endMileage - startMileage - totalMileageAllowance,
					price: Number((endMileage - startMileage - totalMileageAllowance) * pricing?.mileage_overage_price),
				};
			}
		}

		let tempTotalPrice = totalPrice;
		if (Object.keys(addOns).length > 0) {
			Object.values(addOns).forEach(
				(value) => {
					tempTotalPrice += Number(value.price);
				},
			);
		}

		if (pricing?.mileage_package?.value) {
			// mileage package cost must be added as well
			tempTotalPrice += Number(pricing?.mileage_package.price);
		}

		if (pricing?.extras && pricing.extras.length > 0) {
			pricing.extras.forEach((extra, index) => {
				tempTotalPrice += Number(extra.price);
			});
		}

		const discount = Number(getDiscountedAmount(tempTotalPrice));
		const subTotal = tempTotalPrice - discount;

		const cardFee = pricing?.card_fee ? ((subTotal * pricing?.card_fee) / 100) : 0;
		const serviceFee = ((subTotal * pricing?.service_tax) / 100);
		const total = subTotal + serviceFee + cardFee;
		return total;
	},

	getSecurityDepositString: function (allTransactions) {
		let totalHold = 0;
		let totalCaptured = 0;
		allTransactions.forEach(transaction => {
			if (transaction.isSecurityHold) {
				if (transaction.transactionStatus === PAYMENT_STATUSES.AUTHORIZED) {
					totalHold += Number(transaction.amount);
				} else if (
					transaction.transactionStatus === PAYMENT_STATUSES.CAPTURED ||
					transaction.transactionStatus === PAYMENT_STATUSES.OFFLINE_CHARGED
				) {
					totalCaptured += Number(transaction.amount);
				} else if (
					transaction.transactionStatus === PAYMENT_STATUSES.PARTIALLY_CAPTURED
				) {
					totalCaptured += Number(transaction.captureAmount);
				}
			}
		});

		return {totalHold, totalCaptured};

	},

	getPaymentStatusString: function (allTransactions, totalPrice) {
		let totalPaid = 0;
		allTransactions.forEach(trans => {
			if (!trans.isSecurityHold) {
				if (
					trans.transactionStatus === PAYMENT_STATUSES.CHARGED ||
					trans.transactionStatus === PAYMENT_STATUSES.OFFLINE_CHARGED
				) {
					totalPaid += Number(trans.amount);
				} else if (
					trans.transactionStatus === PAYMENT_STATUSES.PARTIALLY_REFUNDED
				) {
					totalPaid += Number(trans.amount) - Number(trans.refundAmount);
				}
			}
		});

		if (totalPaid === 0) {
			return 'Not Paid';
		}

		if (totalPaid < totalPrice) {
			return 'Partially Paid';
		}
		if (totalPaid > totalPrice) {
			return 'Overpaid';
		}
		return 'Paid in Full';
	},

	getPaymentTileTitle: function (transaction, totalPrice) {
		if (transaction.isSecurityHold) {
			return 'Security Deposit';
		}

		if (Number(transaction.amount) === totalPrice) {
			return 'Full Payment';
		}
		return 'Partial Payment';
	}

}

export default functions
