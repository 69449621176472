// Import the functions you need from the SDKs you need
import {initializeApp,} from "firebase/app";
// https://firebase.google.com/docs/web/setup#available-libraries
import {getFirestore} from 'firebase/firestore';
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage";
import {getMessaging} from "firebase/messaging";

//prod admin config final for release
const firebaseConfig = {
	apiKey: "AIzaSyBJCdKlQGbJ8fauzvdJXNGIPZ4E6_glYy8",
	authDomain: "admin-fbf4f.firebaseapp.com",
	projectId: "admin-fbf4f",
	storageBucket: "admin-fbf4f.appspot.com",
	messagingSenderId: "1002669219437",
	appId: "1:1002669219437:web:4bc5bd12cf67dacd35a964"
};

// drip drop config
// const firebaseConfig = {
// 	apiKey: "AIzaSyAO1_9NrIFZt-yZt9uKP023kKJ6hcyge5Q",
// 	authDomain: "diamondhands-76bb5.firebaseapp.com",
// 	projectId: "diamondhands-76bb5",
// 	storageBucket: "diamondhands-76bb5.appspot.com",
// 	messagingSenderId: "515219835409",
// 	appId: "1:515219835409:web:c03797e5adf1a7b0efbfb0",
// 	measurementId: "G-1TYDB0BRT7"
// };


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);
const firebaseMessaging = getMessaging(firebaseApp);


export {firebaseApp, firestore, auth, firebaseStorage, firebaseMessaging}

